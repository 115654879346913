import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Tooltip, Table, Row, Col, Tag, PageHeader } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import { InvalidUsersList } from "../../Action/InvalidUserAction";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

function InvalidUsers() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  let InvalidUsersReducer = useSelector((state) => state?.InvalidUsersReducer);

  let [List, setList] = useState([]);
  let [page, setPage] = useState(1);
  let [pageSize, setpageSize] = useState(10);
  let [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getData();
    if (InvalidUsersReducer?.InvalidUsersTableCount) {
      setTotalCount(InvalidUsersReducer?.InvalidUsersTableCount);
    }
    // eslint-disable-next-line
  }, [page, pageSize, id]);

  async function getData() {
    dispatch(InvalidUsersList(page, pageSize, id));
  }

  useEffect(() => {
    if (InvalidUsersReducer?.InvalidUsersListArray) {
      setList(InvalidUsersReducer?.InvalidUsersListArray);
      setTotalCount(InvalidUsersReducer?.InvalidUsersTableCount);
    }

    if (InvalidUsersReducer?.InvalidUsersTableCount <= (page - 1) * pageSize) {
      setPage(1);
    }
    // eslint-disable-next-line
  }, [InvalidUsersReducer]);

  function onChangeFunctionality(pagination, filters, sorter, extra) {
    setPage(pagination?.current);
    setpageSize(pagination?.pageSize);
  }

  let dataSource =
    List &&
    List?.map((data, index) => ({
      key: index,
      _id: data?._id,
      name: data?.name,
      email: data?.email,
      mobile: data?.mobile,
      is_user_type: data?.is_user_type,
    }));

  let columns = [
    {
      title: "User Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name.localeCompare(b?.name),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a?.email.localeCompare(b?.email),
    },

    {
      title: "Mobile Number",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => a?.mobile - b?.mobile,
    },

    {
      title: "Type Record",
      dataIndex: "is_user_type",
      key: "is_user_type",
      sorter: (a, b) => a?.status.localeCompare(b?.status),
      render: (text, row) => (
        <Space size="middle">
          <Tag
            color={
              (["invalid"].includes(row?.is_user_type) && "gold") ||
              (["duplicate"].includes(row?.is_user_type) && "red")
            }
          >
            {row?.is_user_type}
          </Tag>
        </Space>
      ),
    },

    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View Detail">
            <Link to={`/failed-user-detail/${record?._id}`}>
              <EyeOutlined className="table_icon" />
            </Link>
          </Tooltip>
          <Tooltip title="Edit">
            {record?.is_user_type === "invalid" && (
              <Link to={`/failed-edit-users/${record?._id}`}>
                <EditOutlined className="table_icon" />
              </Link>
            )}
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {InvalidUsersReducer?.InvalidUsersTableLoader === true && <Loader />}
      <div className="invalidUsers">
        <Row>
          <Col span={24}>
            <div className="parent-container">
              <div className="left">
                <h2>Failed Imported Users</h2>
              </div>
              <div className="right">
                <PageHeader
                  onBack={() => navigate(-1)}
                  title="Go Back"
                ></PageHeader>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Table
              className="failed_user_list_table"
              columns={columns}
              dataSource={dataSource}
              onChange={onChangeFunctionality}
              pagination={{
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                total: totalCount,
              }}
            />
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}

export default InvalidUsers;
