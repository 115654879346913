import React, { useEffect } from "react";
import SiteLayout from "../../Reusable/Layout/SiteLayout";
import { connect } from "react-redux";
import {
  PageHeader,
  Descriptions,
  Row,
  Col,
  Timeline,
  Card,
  Badge,
  Skeleton,
  Button,
  Tag,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { userHistoryDetail } from "../../../Action/UserAction";
import { useState } from "react";
import {
  getColumnData,
  getDate,
  getRupeesSign,
} from "../../../Config/CustomFunction";
import HistoryModel from "./HistoryModel";

// State
const mapState = (props) => ({
  userHistoryData: props?.UserReducer?.usersHistoryDetail,
  SelectLoader: props?.UserReducer?.loaderState,
  messages: props?.UserReducer?.MessageState,
});

// Action
const mapDispatch = (dispatch) => {
  return {
    userHistory: (id) => dispatch(userHistoryDetail(id)),
  };
};

function UserHistory(store) {
  let navigate = useNavigate();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [activeData, setActiveData] = useState(null);
  const [singalData, setSingalData] = useState(null);

  useEffect(() => {
    if (store?.userHistoryData) {
      setActiveData(store?.userHistoryData?.activeData);
      setList(store?.userHistoryData?.oldHistoryData);
    }
    // eslint-disable-next-line
  }, [store?.userHistoryData]);

  async function getData(id) {
    await store.userHistory(id);
  }

  const showModal = (props) => {
    setSingalData(props);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await getData(id);
      })();
    }
    //eslint-disable-next-line
  }, [id]);

  return (
    <>
      <SiteLayout>
        {store?.SelectLoader === true && ""}
        <HistoryModel
          show={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setSingalData={setSingalData}
          data={singalData}
        />

        <div className="userHistory">
          <Row>
            <Col span={24}>
              <div className="parent-container">
                <div className="left">
                  <h2>User History</h2>
                </div>
                <div className="right">
                  <PageHeader
                    onBack={() => navigate(-1)}
                    title="Go Back"
                  ></PageHeader>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} xl={24} className="mainCol">
              <div className="userHistory-header">
                <PageHeader>
                  <Skeleton
                    active
                    loading={store?.SelectLoader}
                    paragraph={{ rows: 1 }}
                  >
                    <Row className="history-role">
                      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <span> Name : </span>
                        <span>{activeData?.name}</span>
                      </Col>
                      <Col
                        xs={24}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className="text-center"
                      >
                        <span> Mobile : </span>
                        <span>{activeData?.mobile}</span>
                      </Col>
                      <Col
                        xs={24}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className="text-end"
                      >
                        <span> Email : </span>
                        <span>{activeData?.email}</span>
                      </Col>
                    </Row>
                  </Skeleton>
                </PageHeader>
              </div>

              <Card title="" size="small" className="customCard">
                {!store?.SelectLoader && list?.length === 0 && (
                  <div className="text-center text-gray">
                    User History Not Available
                  </div>
                )}

                <Timeline>
                  {list &&
                    list?.map((data, index) => {
                      return (
                        <Timeline.Item key={index}>
                          <Tag className="dateTag">
                            {`${getDate(data?.start_Date)} to ${getDate(
                              data?.end_Date
                            )}`}
                          </Tag>

                          <Card className="customCard2">
                            <Skeleton
                              active
                              loading={store?.SelectLoader}
                              paragraph={{ rows: 4 }}
                            >
                              <Descriptions title="" bordered>
                                <Descriptions.Item
                                  label="Approve date"
                                  span={2}
                                >
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getDate(data?.approve_date)}
                                  />
                                </Descriptions.Item>

                                <Descriptions.Item label="Sequence number" span={1}>
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getColumnData(data?.sequence_no)}
                                  />
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label="Policy number"
                                  span={2}
                                >
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getColumnData(data?.policy_number)}
                                  />
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label="Mode of Payment"
                                  span={1}
                                >
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getColumnData(data?.payment)}
                                  />
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label="Transaction number"
                                  span={2}
                                >
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getColumnData(
                                      data?.transaction_number
                                    )}
                                  />
                                </Descriptions.Item>

                                <Descriptions.Item
                                  label="Transaction amount"
                                  span={2}
                                >
                                  <Badge
                                    className="uppercase"
                                    status="processing"
                                    text={getRupeesSign(data?.paidAmount)}
                                  />
                                </Descriptions.Item>
                              </Descriptions>
                            </Skeleton>

                            <Button
                              className="showMoreButton"
                              loading={store?.SelectLoader}
                              type="primary"
                              onClick={() => showModal(data)}
                            >
                              {store?.SelectLoader
                                ? "Please Wait"
                                : "Show More"}
                            </Button>
                          </Card>
                        </Timeline.Item>
                      );
                    })}
                </Timeline>
              </Card>
            </Col>
          </Row>
        </div>
      </SiteLayout>
    </>
  );
}

export default connect(mapState, mapDispatch)(UserHistory);
