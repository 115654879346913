import { combineReducers } from "redux";
import AuthReducer from "../Reducer/AuthReducer";
import UserReducer from "../Reducer/UserReducer";
import DocumentReducer from "../Reducer/DocumentReducer";
import DashboardReducer from "../Reducer/DashboardReducer";
import SiteSettingReducer from "../Reducer/SiteSettingReducer";
import ImportUserReducer from "../Reducer/ImportUserReducer";
import InvalidUsersReducer from "../Reducer/InvalidUsersReducer";

export default combineReducers({
  AuthReducer,
  UserReducer,
  DocumentReducer,
  DashboardReducer,
  SiteSettingReducer,
  ImportUserReducer,
  InvalidUsersReducer,
});
