// Admin Login
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

// Users
export const USERS_SELECT = "USERS_SELECT";
export const LOADER = "LOADER";
export const REFRESH = "REFRESH";
export const USER_UPDATE = "USER_UPDATE";
export const USERS_DETAIL = "USERS_DETAIL";
export const USER_SEARCH = "USER_SEARCH";
export const STATE_LIST = "STATE_LIST";
export const UPDATE_REGISTER_USERS = "UPDATE_REGISTER_USERS";
export const VERIFY_CAPTCHA_UPDATE_USER = "VERIFY_CAPTCHA_UPDATE_USER";
export const PRINT_DATA_STORE = "PRINT_DATA_STORE";
export const PDF_BUTTTON_LOADER = "PDF_BUTTTON_LOADER";
export const UPDATE_REGISTER_FORM_BUTTON_LOADING =
  "UPDATE_REGISTER_FORM_BUTTON_LOADING";
export const CHOLA_BUTTON_LOADING = "CHOLA_BUTTON_LOADING";
export const EMD_BUTTON_LOADING = "EMD_BUTTON_LOADING";
export const SAMPLE_EXCEL_FILE_BTN_LOADING = "SAMPLE_EXCEL_FILE_BTN_LOADING";
export const USER_HISTORY_DETAIL = "USER_HISTORY_DETAIL";

// Review Document Section
export const USERS_SELECT_INACTIVE = "USERS_SELECT_INACTIVE";

// Setting
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";
export const UPDATE_SETTING = "UPDATE_SETTING";

// Dashboard
export const COUNT_USERS = "COUNT_USERS";

// Site Setting
export const COLLAPSED_MENU = "COLLAPSED_MENU";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const STORE_SETTING_DATA = "STORE_SETTING_DATA";
export const BUTTON_LOADING = "BUTTON_LOADING";

// Excel List Failed Table
export const EXCEL_LIST_SELECT = "EXCEL_LIST_SELECT";
export const EXCEL_USERS_TABLE_LOADER = "EXCEL_USERS_TABLE_LOADER";

// Invalid Users
export const INVALID_USERS_LIST_SELECT = "INVALID_USERS_LIST_SELECT";
export const INVALID_USERS_TABLE_LOADER = "INVALID_USERS_TABLE_LOADER";

export const INVALID_USERS_DETAIL = "INVALID_USERS_DETAIL";
export const INVALID_USERS_DETAIL_LOADER = "INVALID_USERS_DETAIL_LOADER";

export const UPDATE_INVALID_USERS = "UPDATE_INVALID_USERS";

export const INVALID_USER_UPDATE_BTN_LOADING =
  "INVALID_USER_UPDATE_BTN_LOADING";

export const PDF_BTN_LOADER_INVALID_USER = "PDF_BTN_LOADER_INVALID_USER";
export const PRINT_DATA_STORE_INVALID_USER = "PRINT_DATA_STORE_INVALID_USER";
export const VERIFY_CAPTCHA_INVALID_USER = "VERIFY_CAPTCHA_INVALID_USER";
export const INVALID_USER_DELETE = "INVALID_USER_DELETE";

export const USER_EXCEL_UPLOAD_RESULT = "USER_EXCEL_UPLOAD_RESULT";
