import { ADMIN_LOGIN_SUCCESS, ADMIN_LOGOUT } from "../Action/ActionTypes";

const initialState = {
  tokenState: [],
  isLoggedInAdminState:
    window.localStorage.getItem("Bearer") &&
    window.localStorage.getItem("Bearer")
      ? true
      : false,
};

export default function AuthReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedInAdminState: payload.isLoggedInAdminState,
        tokenState: payload.tokenState,
      };

    case ADMIN_LOGOUT:
      return {
        isLoggedInAdminState: false,
      };

    default:
      return state;
  }
}
