import { message } from "antd";
import {
  EXCEL_LIST_SELECT,
  EXCEL_USERS_TABLE_LOADER,
} from "../Action/ActionTypes";
import api from "../Config/Api";

// Select All
export const AllExcelListSelect = (page, pageSize) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    try {
      const config = {
        params: {
          pageNumber: page,
          nPerPage: pageSize,
        },
      };

      api.get("admin/getExcelDetails", config).then(
        (response) => {
          let data = response?.data?.data?.data;
          let count = response?.data?.data?.count;
          let responseStatus = response?.data?.code;

          let params = {
            count,
            data,
          };

          if (responseStatus === 200) {
            dispatch({
              type: EXCEL_LIST_SELECT,
              payload: params,
            });
            dispatch(tableLoading(false));
          }
        },
        function (error) {
          ErrorMessageOnTable(dispatch, error);
        }
      );
    } catch (error) {
      ErrorMessageOnTable(dispatch, error);
    }
  };
};

export const tableLoading = (para) => {
  return {
    type: EXCEL_USERS_TABLE_LOADER,
    payload: para,
  };
};

function ErrorMessageOnTable(dispatch, error) {
  if (error && error?.response?.status === 500) {
    message.error(error?.response?.statusText);
  }
  if (error && error?.response?.status === 404) {
    message.error(error?.response?.statusText);
  }
  dispatch(tableLoading(false));
}
