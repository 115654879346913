import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Skeleton,
  Tabs,
  Descriptions,
  Badge,
  Image,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InvalidUsersDetailAction } from "../../Action/InvalidUserAction";
import { FilePdfOutlined } from "@ant-design/icons";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";

import {
  insuranceFunction,
  insuranceArray,
  OwnPropertyFunction,
  ownPropertyArray,
  getColumnData,
  getDate,
  getFacilitator,
  removeDashFromText,
  getIncome,
} from "../../Config/CustomFunction";
import ErrorImageComponent from "../Reusable/Component/ErrorImageComponent.jsx";

function InvalidUsersDetail() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const { Meta } = Card;
  const { TabPane } = Tabs;

  const InvalidUsersReducer = useSelector(
    (state) => state?.InvalidUsersReducer
  );

  let [userDetail, setUserDetail] = useState([]);

  let [ErrorImage, setErrorImage] = useState({
    user_aadhaar_card_front_img: false,
    user_aadhaar_ard_back_img: false,
    user_pan_card_img: false,
    nominee_aadhar_card_front_img: false,
    nominee_aadhar_card_back_img: false,
    passbook_cancel_cheque_img: false,
    signature_img: false,
    transaction_receipt_img: false,
  });

  useEffect(() => {
    if (id) {
      dispatch(InvalidUsersDetailAction(id));
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (InvalidUsersReducer?.InvalidUsersDetail) {
      setUserDetail(InvalidUsersReducer?.InvalidUsersDetail);
    }
    // eslint-disable-next-line
  }, [InvalidUsersReducer?.InvalidUsersDetail]);

  return (
    <SiteLayout>
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => navigate(-1)}
              title="Go Back"
            ></PageHeader>
          </Col>
        </Row>
      </div>

      <div className="UserDetailScreen">
        <Row>
          <Col xs={24} xl={24}>
            <Tabs type="card">
              <TabPane tab="Membership Proposer Details" key="1">
                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Date" bordered>
                    <Descriptions.Item label="Date Of Application" span={2}>
                      <Badge
                        status="processing"
                        text={getDate(userDetail?.application_date)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Membership Valid Till" span={2}>
                      <Badge
                        status="processing"
                        text={getDate(userDetail?.valid_till)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 2 }}
                >
                  <br />
                  <Descriptions title="Facilitator Information" bordered>
                    <Descriptions.Item
                      label="Facilitator"
                      span={3}
                      className="FacilitatorClass"
                    >
                      <Badge
                        status="processing"
                        text={getFacilitator(userDetail?.facilitator)}
                      />
                    </Descriptions.Item>

                    {userDetail?.facilitator === "gramhaat" && (
                      <>
                        <Descriptions.Item label="Facilitator name" span={2}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={getColumnData(userDetail?.facilitator_name)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="Facilitator code" span={1}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={removeDashFromText(
                              userDetail?.facilitator_code
                            )}
                          />
                        </Descriptions.Item>
                      </>
                    )}

                    {userDetail?.facilitator === "agent" && (
                      <>
                        <Descriptions.Item label="Agent name" span={2}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={getColumnData(userDetail?.agent_name)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="Agent code" span={1}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={removeDashFromText(userDetail?.agent_code)}
                          />
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </Skeleton>
                <br />
                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 6 }}
                >
                  <Descriptions title="Proposer Information" bordered>
                    <Descriptions.Item label="Full name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.name)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="MA Yojana number" span={2}>
                      <Badge
                        status="processing"
                        className="uppercase"
                        text={getColumnData(userDetail?.yojana_number)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of birth" span={2}>
                      <Badge
                        status="processing"
                        text={getDate(userDetail?.dob)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Occupation" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.business)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Net monthly income" span={2}>
                      <Badge
                        status="processing"
                        text={getIncome(userDetail?.monthly_income)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Marital status" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.marital_status)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Total family members" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.total_family_members)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Height (inch)" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.height)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Weight (Kg)" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.weight)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Pre disease (if any)" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.disease)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Primary health" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.primary_health)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Gender" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.gender)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Blood group" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.blood_group)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <br />
                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 6 }}
                >
                  <Descriptions title="Proposer Contact Details" bordered>
                    <Descriptions.Item label="Email" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.email)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Contact Number" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.mobile)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee contact number" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.nomineeNumber)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Whatsapp number" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.whatsapp_no)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Country" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.country)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="State" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.state)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="City / Town / Village" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.city)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="District" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.district)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Taluka" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.taluka)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Pin code" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.pin_code)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Address" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.address)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 3 }}
                >
                  <Descriptions title="Proposer Bank Details" bordered>
                    <Descriptions.Item label="Bank name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.bank_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Branch name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.branch_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Account number" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.account_no)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="IFSC Code" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.ifsc_code)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="UPI ID" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.upi_id)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Proposer Nominee Declaration" bordered>
                    <Descriptions.Item label="Nominee name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.nominee_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee date of birth" span={2}>
                      <Badge
                        status="processing"
                        text={getDate(userDetail?.nominee_dob)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee relation" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.relative)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 3 }}
                >
                  <Descriptions title="Other Information" bordered>
                    <Descriptions.Item label="Self registered vehicle" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(
                          userDetail?.self_registered_vehicle
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Vehicle type" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.vehicle_type)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="RTO number" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.rto_number)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Insurance (if any)" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={insuranceFunction(userDetail?.insurance)}
                      />
                      {insuranceArray && insuranceArray?.join(", ")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Existing loan" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.existing_loan)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Own property" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={OwnPropertyFunction(userDetail?.own_property)}
                      />
                      {ownPropertyArray && ownPropertyArray?.join(", ")}
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <br />

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="User Status" bordered>
                    <Descriptions.Item label="Status" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.status)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Message" span={1}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.message)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
              </TabPane>

              <TabPane tab="Membership Proposer Documents" key="2">
                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Proposer Statutory Disclosure" bordered>
                    <Descriptions.Item label="Aadhaar card number" span={2}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.aadharcard_number)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="PAN card number" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={getColumnData(userDetail?.pan_card_number)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Nominee aadhaar card number"
                      span={3}
                    >
                      <Badge
                        status="processing"
                        text={getColumnData(
                          userDetail?.nominee_aadharcard_number
                        )}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 12 }}
                >
                  <div className="site-card-wrapper">
                    <Row gutter={24}>
                      <Col span={2}> </Col>
                      {/* AADHAR FRONT START */}
                      <Col span={4}>
                        <Card
                          title="User Aadhaar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.aadharcard_front_image &&
                          userDetail?.aadharcard_front_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={userDetail?.aadharcard_front_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Front"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.aadharcard_front_image ? (
                                <ErrorImageComponent name="Front" />
                              ) : ErrorImage?.user_aadhaar_card_front_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card Front"
                                      src={userDetail?.aadharcard_front_image}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          user_aadhaar_card_front_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Front"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Front" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* AADHAR FRONT END */}
                      {/* AADHAR BACK START */}
                      <Col span={4}>
                        <Card
                          title="User Aadhaar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.aadharcard_back_image &&
                          userDetail?.aadharcard_back_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={userDetail?.aadharcard_back_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Back"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.aadharcard_back_image ? (
                                <ErrorImageComponent name="Back" />
                              ) : ErrorImage?.user_aadhaar_ard_back_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card Back"
                                      src={userDetail?.aadharcard_back_image}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          user_aadhaar_ard_back_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Back"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Back" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* AADHAR BACK END */}

                      {/* Pan Card Start */}
                      {/* {Pan_Card?.split("/").pop().slice(0, 4) !== "null" && ( */}
                      {userDetail?.pan_card_front_image && (
                        <Col span={4}>
                          <Card
                            title="User PAN Card"
                            bordered={false}
                            className="text-center"
                          >
                            {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                              false &&
                            userDetail?.pan_card_front_image &&
                            userDetail?.pan_card_front_image
                              ?.split(".")
                              .pop()
                              .slice(0, 3) === "pdf" ? (
                              <>
                                <a
                                  href={userDetail?.pan_card_front_image}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Card hoverable cover={<FilePdfOutlined />}>
                                    <Meta title={"Front"} />
                                  </Card>
                                </a>
                              </>
                            ) : (
                              <>
                                {!userDetail?.pan_card_front_image ? (
                                  <ErrorImageComponent name="Front" />
                                ) : ErrorImage?.user_pan_card_img === false ? (
                                  <Card
                                    hoverable
                                    cover={
                                      <Image
                                        alt="Pan Card"
                                        src={userDetail?.pan_card_front_image}
                                        onError={(e) => {
                                          setErrorImage({
                                            ...ErrorImage,
                                            user_pan_card_img: true,
                                          });
                                        }}
                                      />
                                    }
                                  >
                                    <Meta title={"Front"} />
                                  </Card>
                                ) : (
                                  <ErrorImageComponent name="Front" />
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                      )}

                      {/* Pamd Card End */}
                      {/* Nomini Aadhar card front start */}
                      <Col span={4}>
                        <Card
                          title="Nominee Aadhar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.nominee_aadharcard_front_image &&
                          userDetail?.nominee_aadharcard_front_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={
                                  userDetail?.nominee_aadharcard_front_image
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Front"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.nominee_aadharcard_front_image ? (
                                <ErrorImageComponent name="Front" />
                              ) : ErrorImage?.nominee_aadhar_card_front_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card"
                                      src={
                                        userDetail?.nominee_aadharcard_front_image
                                      }
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          nominee_aadhar_card_front_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Front"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Front" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>

                      {/* nomini AAdhar back start */}
                      <Col span={4}>
                        <Card
                          title="Nominee Aadhar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.nominee_aadharcard_back_image &&
                          userDetail?.nominee_aadharcard_back_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={userDetail?.nominee_aadharcard_back_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Back"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.nominee_aadharcard_back_image ? (
                                <ErrorImageComponent name="Back" />
                              ) : ErrorImage?.nominee_aadhar_card_back_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card"
                                      src={
                                        userDetail?.nominee_aadharcard_back_image
                                      }
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          nominee_aadhar_card_back_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Back"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Back" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* nomini AAdhar back end */}
                      <Col span={2}> </Col>
                    </Row>
                  </div>

                  <div className="site-card-wrapper">
                    <Row gutter={24}>
                      <Col span={2}> </Col>
                      {/* Bank Detail Proof START */}
                      <Col span={4}>
                        <Card title="" bordered={false} className="text-center">
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.bank_details_proof_image &&
                          userDetail?.bank_details_proof_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={userDetail?.bank_details_proof_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Passbook / Cancel Cheque"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.bank_details_proof_image ? (
                                <ErrorImageComponent name="Passbook / Cancel Cheque" />
                              ) : ErrorImage?.passbook_cancel_cheque_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Bank Detail Proof"
                                      src={userDetail?.bank_details_proof_image}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          passbook_cancel_cheque_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Passbook / Cancel Cheque"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Passbook / Cancel Cheque" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>

                      {/* Bank Detail Proof end */}

                      {/* SignatureImage  Start */}
                      <Col span={4}>
                        <Card title="" bordered={false} className="text-center">
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.signature_image &&
                          userDetail?.signature_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={userDetail?.signature_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Signature"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!userDetail?.signature_image ? (
                                <ErrorImageComponent name="Signature" />
                              ) : ErrorImage?.signature_img === false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Signature"
                                      src={userDetail?.signature_image}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          signature_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Signature"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Signature" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* SignatureImage end */}
                    </Row>
                  </div>
                  {/* Passbook / Cancel Cheque Start  */}
                </Skeleton>
              </TabPane>

              <TabPane tab="Membership Proposer Payment Details" key="3">
                <Skeleton
                  active
                  loading={InvalidUsersReducer?.InvalidUsersDetailLoader}
                  paragraph={{ rows: 5 }}
                >
                  <Descriptions title="" bordered>
                    <Descriptions.Item label="Transaction number" span={3}>
                      <Badge
                        status="processing"
                        text={getColumnData(userDetail?.transaction_number)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                  {/* Transcation recept image start */}
                  <Row gutter={24}>
                    <Col span={5}>
                      <Card title="" bordered={false} className="text-center">
                        <Card title="" bordered={false} className="text-center">
                          {InvalidUsersReducer?.InvalidUsersDetailLoader ===
                            false &&
                          userDetail?.transaction_receipt_image &&
                          userDetail?.transaction_receipt_image
                            ?.split(".")
                            .pop()
                            .slice(0, 3) === "pdf" ? (
                            <>
                              {/* if pdf avilable it display */}
                              <a
                                href={userDetail?.transaction_receipt_image}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Transaction Receipt"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {/* else image dispaly */}
                              {!userDetail?.transaction_receipt_image ? (
                                <ErrorImageComponent name="Transaction Receipt" /> //if key not exits
                              ) : ErrorImage?.transaction_receipt_img ===
                                false ? (
                                // if error false image dispaly
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Transaction Receipt"
                                      src={
                                        userDetail?.transaction_receipt_image
                                      }
                                      onError={(e) => {
                                        // if error in image set true error in state
                                        setErrorImage({
                                          ...ErrorImage,
                                          transaction_receipt_img: true,
                                        });
                                        // e.currentTarget.src = NoImage;
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Transaction Receipt"} />
                                </Card>
                              ) : (
                                // if error true no image display in this component
                                <ErrorImageComponent name="Transaction Receipt" />
                              )}
                            </>
                          )}
                        </Card>
                      </Card>
                    </Col>
                  </Row>
                  {/* recept image end */}
                </Skeleton>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </SiteLayout>
  );
}
export default InvalidUsersDetail;
