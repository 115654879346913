import {
  EXCEL_USERS_TABLE_LOADER,
  EXCEL_LIST_SELECT,
} from "../Action/ActionTypes";

const initialState = {
  tableExcelLoader: true,
  excelListArray: null,
  excelTableCount: null,
};

export default function ImportUserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EXCEL_LIST_SELECT:
      return {
        ...state,
        excelListArray: payload?.data,
        excelTableCount: payload?.count,
      };

    case EXCEL_USERS_TABLE_LOADER:
      return { ...state, tableExcelLoader: payload };

    default:
      return state;
  }
}
