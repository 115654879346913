import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Skeleton,
  Card,
  Image,
  PageHeader,
  Empty,
  Descriptions,
  Badge,
} from "antd";
import { connect } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import { userDetailAction } from "../../Action/UserAction";
import "./userstyle.scss";

const mapState = (props) => ({
  SingleData: props?.UserReducer?.SingelUser,
  SelectLoader: props?.UserReducer?.loaderState,
});

const mapDispatch = (dispatch) => {
  return {
    userDetail: (id) => dispatch(userDetailAction(id)),
  };
};

function DownlaodUserData(store) {
  let navigate = useNavigate();
  const { Meta } = Card;
  const { id } = useParams();
  let [userDetail, setUserDetail] = useState([]);

  useEffect(() => {
    store?.userDetail(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (store?.SingleData !== undefined) {
      setUserDetail(store?.SingleData);
    }

    // eslint-disable-next-line
  }, [store?.SingleData]);

  return (
    <SiteLayout>
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => navigate("/users")}
              title="Go Back"
            ></PageHeader>
          </Col>
        </Row>
      </div>
      <div className="DownloadCertificateScreen">
        <div className="dashboard_wrapper">
          <Row>
            <Col xs={24} xl={24}>
              <Skeleton
                active
                loading={store?.SelectLoader}
                paragraph={{ rows: 1 }}
              >
                <Descriptions title="" bordered>
                  <Descriptions.Item label="User Name" span={2}>
                    <Badge
                      status="processing"
                      text={userDetail?.name || "N/A"}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Membership Number" span={2}>
                    <Badge
                      status="processing"
                      text={userDetail?.policy_number || "N/A"}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            </Col>
          </Row>

          <br />

          <Row gutter={10} className="mb-50">
            <Col
              xs={{
                span: 24,
              }}
              xl={{
                offset: 1,
                span: 4,
              }}
              lg={{
                span: 8,
              }}
              md={{
                span: 12,
              }}
              className="dashbord-grid"
            >
              <Skeleton
                active
                loading={store?.SelectLoader}
                paragraph={{ rows: 14 }}
              >
                {userDetail?.certificate_image ? (
                  <Card title="" bordered={true}>
                    <a
                      href={userDetail?.certificate_image}
                      target="_blank"
                      rel="noreferrer"
                      download={userDetail?.certificate_image}
                    >
                      <Card
                        hoverable
                        cover={
                          <Image
                            preview={false}
                            src={userDetail?.certificate_image}
                            alt="Certificate Image"
                          />
                        }
                      >
                        <Meta title="Certificate Image" />
                      </Card>
                    </a>
                  </Card>
                ) : (
                  <Card title="" bordered={true}>
                    <Card
                      className="noborder"
                      cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    >
                      <Meta title="Certificate Image" />
                    </Card>
                  </Card>
                )}
              </Skeleton>
            </Col>

            <Col
              xs={{
                span: 24,
              }}
              xl={{
                offset: 2,
                span: 4,
              }}
              lg={{
                span: 8,
              }}
              md={{
                span: 12,
              }}
              className="dashbord-grid div2"
            >
              <Skeleton
                active
                loading={store?.SelectLoader}
                paragraph={{ rows: 14 }}
              >
                {userDetail?.certificate_pdf ? (
                  <Card bordered={true} hoverable>
                    <Card
                      className="dashboardCard"
                      bordered={false}
                      cover={
                        <a
                          href={userDetail?.certificate_pdf}
                          target="_blank"
                          rel="noreferrer"
                          download={userDetail?.certificate_pdf}
                        >
                          <Button
                            className="btn-bg"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size="large"
                          >
                            Download Certificate PDF
                          </Button>
                        </a>
                      }
                    ></Card>
                  </Card>
                ) : (
                  <Card title="" bordered={true}>
                    <Card
                      className="noborder"
                      cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    >
                      <Meta title="Certificate PDF" />
                    </Card>
                  </Card>
                )}
              </Skeleton>
            </Col>

            <Col
              xs={{
                span: 24,
              }}
              xl={{
                offset: 2,
                span: 4,
              }}
              lg={{
                span: 8,
              }}
              md={{
                span: 12,
              }}
              className="dashbord-grid div3"
            >
              <Skeleton
                active
                loading={store?.SelectLoader}
                paragraph={{ rows: 14 }}
              >
                {userDetail?.invoice_pdf ? (
                  <Card bordered={true} hoverable>
                    <Card
                      className="dashboardCard card3"
                      bordered={false}
                      cover={
                        <a
                          href={userDetail?.invoice_pdf}
                          target="_blank"
                          rel="noreferrer"
                          download={userDetail?.invoice_pdf}
                        >
                          <Button
                            className="btn-bg"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size="large"
                          >
                            Download Invoice PDF
                          </Button>
                        </a>
                      }
                    ></Card>
                  </Card>
                ) : (
                  <Card title="" bordered={true}>
                    <Card
                      className="noborder"
                      cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    >
                      <Meta title="Invoice PDF" />
                    </Card>
                  </Card>
                )}
              </Skeleton>
            </Col>

            <Col
              xs={{
                span: 24,
              }}
              xl={{
                offset: 2,
                span: 4,
              }}
              lg={{
                span: 6,
              }}
              md={{
                span: 12,
              }}
              className="dashbord-grid div3"
            >
              <Skeleton
                active
                loading={store?.SelectLoader}
                paragraph={{ rows: 14 }}
              >
                {userDetail?.application_form_pdf ? (
                  <Card bordered={true} hoverable>
                    <Card
                      className="dashboardCard card4"
                      bordered={false}
                      cover={
                        <a
                          href={userDetail?.application_form_pdf}
                          target="_blank"
                          rel="noreferrer"
                          download={userDetail?.application_form_pdf}
                        >
                          <Button
                            className="btn-bg"
                            shape="round"
                            icon={<DownloadOutlined />}
                            size="large"
                          >
                            Application PDF
                          </Button>
                        </a>
                      }
                    ></Card>
                  </Card>
                ) : (
                  <Card title="" bordered={true}>
                    <Card
                      className="noborder"
                      cover={
                        <Empty
                          description=""
                          src={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      }
                    >
                      <Meta title="Application PDF Not Available" />
                    </Card>
                  </Card>
                )}
              </Skeleton>
            </Col>
          </Row>
        </div>
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(DownlaodUserData);
