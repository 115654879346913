import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Tooltip, Table, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { AllExcelListSelect } from "../../Action/ImportUserAction";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import Loader from "../../Component/Reusable/Loader/Loader";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import moment from "moment";

function Importusers() {
  const dispatch = useDispatch();
  let ImportUserReducer = useSelector((state) => state?.ImportUserReducer);
  let [List, setList] = useState([]);
  let [page, setPage] = useState(1);
  let [pageSize, setpageSize] = useState(10);
  let [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getData();
    if (ImportUserReducer?.excelTableCount) {
      setTotalCount(ImportUserReducer?.excelTableCount);
    }
    // eslint-disable-next-line
  }, [page, pageSize]);

  async function getData() {
    dispatch(AllExcelListSelect(page, pageSize));
  }

  useEffect(() => {
    if (ImportUserReducer?.excelListArray) {
      setList(ImportUserReducer?.excelListArray);
      setTotalCount(ImportUserReducer?.excelTableCount);
    }

    if (ImportUserReducer?.excelTableCount <= (page - 1) * pageSize) {
      setPage(1);
    }
    // eslint-disable-next-line
  }, [ImportUserReducer]);

  function onChangeFunctionality(pagination, filters, sorter, extra) {
    setPage(pagination?.current);
    setpageSize(pagination?.pageSize);
  }

  let dataSource =
    List &&
    List?.map((data, index) => ({
      key: index,
      _id: data?._id,
      excelFileName: data?.excelFileName,
      excelFilePath: `${process.env.REACT_APP_API_ENDPOINT}${data?.excelFileName}`,
      createdAt: moment
        .utc(data?.createdAt)
        .local()
        .format("DD/MM/YYYY | hh:mm:ss A"),
      numberOfFails: data?.numberOfFails,
    }));

  let columns = [
    {
      title: "Excel file",
      dataIndex: "excelFileName",
      sorter: (a, b) => a?.excelFileName.localeCompare(b?.excelFileName),
    },

    {
      title: "Date and time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a?.createdAt.localeCompare(b?.createdAt),
    },

    {
      title: "Number of fails",
      dataIndex: "numberOfFails",
      key: "numberOfFails",
      sorter: (a, b) => a?.numberOfFails - b?.numberOfFails,
    },

    {
      title: "Operation",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View Detail">
            <Link to={`/failedusers/${record?._id}`}>
              <EyeOutlined className="table_icon" />
            </Link>
          </Tooltip>

          <Tooltip title="Download">
            <a
              href={record?.excelFilePath}
              target="_blank"
              rel="noreferrer"
              download={record?.excelFilePath}
            >
              <DownloadOutlined className="table_icon" />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <SiteLayout>
      {ImportUserReducer?.tableExcelLoader === true && <Loader />}
      <Row>
        <Col span={12}>
          <h2>Import Users</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataSource}
            onChange={onChangeFunctionality}
            pagination={{
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              total: totalCount,
            }}
          />
        </Col>
      </Row>
    </SiteLayout>
  );
}

export default Importusers;
