
import React from 'react'
import NoImage from "../../../Assets/images/NoImage.png";
import { Card , Image } from "antd";

function ErrorImageComponent(params) {
const { Meta } = Card;
  return (
    <>
    <div className="remove-preview">
      <Card
        cover={
          <Image
            preview={{
              visible: false,
            }}
            alt={params?.name}
            src={NoImage}
          />
        }
      >
        <Meta title={params?.name} />
      </Card>
    </div>
  </>
  )
}
export default ErrorImageComponent;