import { message } from "antd";
import {
  INVALID_USERS_LIST_SELECT,
  INVALID_USERS_DETAIL,
  INVALID_USERS_TABLE_LOADER,
  INVALID_USERS_DETAIL_LOADER,
  INVALID_USER_UPDATE_BTN_LOADING,
  UPDATE_INVALID_USERS,
  PDF_BTN_LOADER_INVALID_USER,
  PRINT_DATA_STORE_INVALID_USER,
  VERIFY_CAPTCHA_INVALID_USER,
} from "../Action/ActionTypes";
import api from "../Config/Api";

// Select All Invalid Users
export const InvalidUsersList = (page, pageSize, id) => {
  return (dispatch) => {
    dispatch(tableLoading(true));
    try {
      const config = {
        importExcelId: id,
        pageNumber: page,
        nPerPage: pageSize,
      };

      api.post("admin/getFailUserList", config).then(
        (response) => {
          let data = response?.data?.data?.data;
          let count = response?.data?.data?.count;
          let responseStatus = response?.data?.code;

          let params = {
            count,
            data,
          };

          if (responseStatus === 200) {
            dispatch({
              type: INVALID_USERS_LIST_SELECT,
              payload: params,
            });
            dispatch(tableLoading(false));
          }
        },
        function (error) {
          ErrorMessage(error);
          dispatch(tableLoading(false));
        }
      );
    } catch (error) {
      ErrorMessage(error);
      dispatch(tableLoading(false));
    }
  };
};

// Detail Of Invalid Users
export const InvalidUsersDetailAction = (para) => {
  return (dispatch) => {
    dispatch(InvalidUserDetailLoading(true));
    try {
      const config = {
        params: {
          userId: para,
        },
      };
      api.get("admin/getUserDetails", config).then(
        (response) => {
          let data = response?.data?.data;
          let responseStatus = response?.data?.code;

          if (responseStatus === 200) {
            dispatch({
              type: INVALID_USERS_DETAIL,
              payload: data,
            });
            dispatch(InvalidUserDetailLoading(false));
          }
        },
        function (error) {
          dispatch(InvalidUserDetailLoading(false));
          ErrorMessage(error);
        }
      );
    } catch (error) {
      dispatch(InvalidUserDetailLoading(false));
      ErrorMessage(error);
    }
  };
};

// Update User ( insert user into User Table In Review )
export const UpdateInvalidUserAction = (payload) => {
  return async (dispatch) => {
    await dispatch({
      type: INVALID_USER_UPDATE_BTN_LOADING,
      payload: true,
    });

    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await api({
        method: "post",
        url: "user/registration",
        data: payload?.formData,
        config,
      }).then(
        async (response) => {
          if (response?.data?.code === 200) {
            await deleteInvalidUser(payload?.userId);

            await dispatch({
              type: INVALID_USER_UPDATE_BTN_LOADING, // is loading
              payload: false,
            });

            await dispatch({
              type: UPDATE_INVALID_USERS, // is update
              payload: true,
            });
            await payload.navigate("/success/" + response?.data?.data?.userId);
          }
        },
        async function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (
            resErr?.code === 401 ||
            resErr?.code === 500 ||
            resErr?.code === 400
          ) {
            await dispatch({
              type: UPDATE_INVALID_USERS, // is update
              payload: false,
            });
            await dispatch({
              type: INVALID_USER_UPDATE_BTN_LOADING, // is loading
              payload: false,
            });
            await dispatch(verifyCaptchaFalseAction());
            window.grecaptcha.reset();
            message.error(resErr?.message);
          }
        }
      );
    } catch (error) {
      let errMsg = error.response.data ? error.response.data : error.message;
      if (errMsg) {
        await dispatch({
          type: UPDATE_INVALID_USERS, // is update
          payload: false,
        });
        await dispatch({
          type: INVALID_USER_UPDATE_BTN_LOADING, // is loading
          payload: false,
        });
        message.error(errMsg && errMsg.message);
        window.grecaptcha.reset();
      }
    }
  };
};

export function PrintPdf(payload) {
  return async (dispatch) => {
    try {
      dispatch(pdfBtnLoader(true));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      api({
        method: "post",
        url: "user/downloadPdf",
        data: payload?.formData2,
        config,
      }).then(
        (response) => {
          if (response?.data?.code === 201) {
            let datas = response?.data?.data;
            dispatch({
              type: PRINT_DATA_STORE_INVALID_USER,
              payload: datas,
            });
            dispatch(pdfBtnLoader(false));
          }
        },
        function (error) {
          ErrorMessage(error);
          dispatch(pdfBtnLoader(false));
        }
      );
    } catch (error) {
      ErrorMessage(error);
      dispatch(pdfBtnLoader(false));
    }
  };
}

export const verifyCaptchaAction = (params) => {
  return async (dispatch) => {
    try {
      await api.post("verifyCaptcha", params).then(
        async (response) => {
          if (response?.data?.code === 200) {
            await dispatch({
              type: VERIFY_CAPTCHA_INVALID_USER,
              payload: true,
            });
          }
        },

        function (error) {
          let resErr = error?.response?.data ? error?.response?.data : error;
          if (resErr?.code === 401 || resErr?.code === 500) {
            message.error(resErr?.message);
            window.grecaptcha.reset();
          }
        }
      );
    } catch (error) {
      let resErr = error?.response?.data ? error?.response?.data : error;
      if (resErr?.code === 401 || resErr?.code === 500) {
        message.error(resErr?.message);
        window.grecaptcha.reset();
      }
    }
  };
};

async function deleteInvalidUser(id) {
  try {
    await api.delete(`admin/deleteInvalidUser/${id}`).then((response) => {
      let responseStatus = response?.data?.code;
      if (responseStatus === 200) {
        console.log("delete record");
      }
    });
  } catch {
    message.error("something went wrong ");
  }
}

// Set False captcha Action
export const verifyCaptchaFalseAction = () => {
  return {
    type: VERIFY_CAPTCHA_INVALID_USER,
    payload: false,
  };
};

//  step 3  Pdf Button Loader
export const pdfBtnLoader = (para) => {
  return {
    type: PDF_BTN_LOADER_INVALID_USER,
    payload: para,
  };
};

//  Invalid User Detail Loader
export const InvalidUserDetailLoading = (para) => {
  return {
    type: INVALID_USERS_DETAIL_LOADER,
    payload: para,
  };
};

//  Invalid User List Loader
export const tableLoading = (para) => {
  return {
    type: INVALID_USERS_TABLE_LOADER,
    payload: para,
  };
};

function ErrorMessage(error) {
  if (error && error?.response?.status === 500) {
    message.error(error?.response?.statusText);
  }
  if (error && error?.response?.status === 404) {
    message.error(error?.response?.statusText);
  }
}
