import {
  INVALID_USERS_TABLE_LOADER,
  INVALID_USERS_LIST_SELECT,
  INVALID_USERS_DETAIL,
  INVALID_USERS_DETAIL_LOADER,
  UPDATE_INVALID_USERS,
  INVALID_USER_UPDATE_BTN_LOADING,
  PDF_BTN_LOADER_INVALID_USER,
  PRINT_DATA_STORE_INVALID_USER,
  VERIFY_CAPTCHA_INVALID_USER,
} from "../Action/ActionTypes";

const initialState = {
  InvalidUsersTableLoader: true,
  InvalidUsersListArray: null,
  InvalidUsersTableCount: null,
  InvalidUsersDetail: null,
  InvalidUsersDetailLoader: true,
  InvalidUsersUpdateButtonLoading: false, // Update Form Click loading
  InvalidUsersUpdate: null, // Toggel Check Is it User Update Or Not
  printLoader: true,
  PdfData: null,
  VerifyCaptchaInvalideUser: false,
};

export default function InvalidUsersReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INVALID_USERS_LIST_SELECT:
      return {
        ...state,
        InvalidUsersListArray: payload?.data,
        InvalidUsersTableCount: payload?.count,
      };

    case INVALID_USERS_TABLE_LOADER:
      return {
        ...state,
        InvalidUsersTableLoader: payload,
      };

    case INVALID_USERS_DETAIL:
      return {
        ...state,
        InvalidUsersDetail: payload,
      };

    case INVALID_USERS_DETAIL_LOADER:
      return {
        ...state,
        InvalidUsersDetailLoader: payload,
      };

    case PDF_BTN_LOADER_INVALID_USER:
      return {
        ...state,
        printLoader: payload,
      };

    case PRINT_DATA_STORE_INVALID_USER:
      return {
        ...state,
        PdfData: payload,
      };

    case UPDATE_INVALID_USERS:
      return { ...state, InvalidUsersUpdate: payload };

    case INVALID_USER_UPDATE_BTN_LOADING:
      return {
        ...state,
        InvalidUsersUpdateButtonLoading: payload,
      };

    case VERIFY_CAPTCHA_INVALID_USER:
      return { ...state, VerifyCaptchaInvalideUser: payload };

    default:
      return state;
  }
}
