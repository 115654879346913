import React from "react";
import "./404.scss";
import { Button, Result } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { SiteConstants } from "../../../Config/SiteConstants";
import { Helmet } from "react-helmet";

function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>{`Page Not Found - ${SiteConstants.gigSureLabel}`}</title>
      </Helmet>
      <div className="NotFoundPage">
        <div className="divmain">
          <Result
            status="404"
            title="404 Not Found"
            subTitle="Sorry, We can't find that page"
            extra={
              <Button href="/" type="primary" shape="round" size="large">
                <ArrowLeftOutlined /> Go Back
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
