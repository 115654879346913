import _ from "lodash";
import moment from "moment";
import { SiteConstants } from "./SiteConstants";

const { nullCheck, NA, incomeObject } = SiteConstants;
let insuranceArray = [];
let ownPropertyArray = [];

function customSplit(str, maxLength) {
  if (str.length <= maxLength) return str;
  var reg = new RegExp(".{1," + maxLength + "}", "g");
  var parts = str.match(reg);
  return parts.join("\n");
}
// For Edit Page
function NullTextCheckEditForm(params) {
  return nullCheck?.includes(params) ? "" : params;
}
// For Detail Page
function NullTxtChkDetailPage(params) {
  return nullCheck?.includes(params) ? NA : params || NA;
}
// For Detail Page
function insuranceFunction(insurance) {
  insuranceArray =
    insurance &&
    insurance?.split(",")?.map((element) => {
      if (["MEDICAL", "Medical", "MEDICAL INSURANCE"].includes(element)) {
        return "MEDICAL INSURANCE";
      } else if (["LIFE", "Life", "LIFE INSURANCE"].includes(element)) {
        return "LIFE INSURANCE";
      } else if (
        ["PA", "Pa", "PERSONAL ACCIDENT INSURANCE"].includes(element)
      ) {
        return "PERSONAL ACCIDENT INSURANCE";
      } else if (["NONE", "None", "NONE OF ABOVE"].includes(element)) {
        return "NONE";
      } else if (["ALL", "All", "ALL OF ABOVE"].includes(element)) {
        return "MEDICAL INSURANCE , LIFE INSURANCE , PERSONAL ACCIDENT INSURANCE";
      } else {
        return NA;
      }
    });
}

// For Detail Page
function OwnPropertyFunction(ownProperty) {
  ownPropertyArray =
    ownProperty &&
    ownProperty?.split(",")?.map((element) => {
      if (element === "ALL") {
        return "RESIDENTIAL PROPERTY , COMMERCIAL PROPERTY , LAND";
      } else if (element === "NONE") {
        return "NONE";
      } else if (element === "LAND") {
        return "LAND";
      } else if (element === "COMMERCIAL PROPERTY") {
        return "COMMERCIAL PROPERTY";
      } else if (element === "RESIDENTIAL PROPERTY") {
        return "RESIDENTIAL PROPERTY";
      } else {
        return NA;
      }
    });
}

const disableScroll = (e) => {
  e.target.blur();
};

function getFacilitator(params) {
  if (params === "gramhaat") {
    return "Gram-Haat";
  } else {
    return _.capitalize(params) || NA;
  }
}

function removeDashFromText(params) {
  return params?.replace("-", "") || NA;
}

function getColumnData(params) {
  return nullCheck?.includes(params) ? NA : params || NA;
}

function getDate(params) {
  return !params ? NA : moment(new Date(params)).format("DD/MM/YYYY");
}

function getIncome(params) {
  return !params ? NA : incomeObject[params];
}

function getRupeesSign(params) {
  if (!params) {
    return NA;
  } else {
    const totalRs = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    }).format(params);
    return totalRs;
  }
}

export {
  customSplit,
  NullTextCheckEditForm,
  NullTxtChkDetailPage,
  insuranceFunction,
  insuranceArray,
  OwnPropertyFunction,
  ownPropertyArray,
  disableScroll,
  getFacilitator,
  getColumnData,
  removeDashFromText,
  getDate,
  getIncome,
  getRupeesSign,
};
