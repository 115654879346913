import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Row,
  Col,
  Skeleton,
  Tabs,
  Descriptions,
  Badge,
  Image,
  Button,
  Space,
  Modal,
  Input,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import ErrorImageComponent from "../Reusable/Component/ErrorImageComponent";
import { connect } from "react-redux";
import { userDetailAction } from "../../Action/UserAction";
import { ChangeUserStatusAction } from "../../Action/ReviewDocument";
import _ from "lodash";
import { FilePdfOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import SiteLayout from "../../Component/Reusable/Layout/SiteLayout";
import moment from "moment";
import "./userstyle.scss";
import { SiteConstants } from "../../Config/SiteConstants";
import { useSelector } from "react-redux";
import {
  insuranceFunction,
  insuranceArray,
  OwnPropertyFunction,
  ownPropertyArray,
  NullTxtChkDetailPage,
} from "../../Config/CustomFunction";

const { Meta } = Card;
const { confirm } = Modal;

// State
const mapState = (props) => ({
  SingleData: props?.UserReducer,
  SelectLoader: props?.UserReducer?.loaderState,
  isActiveLoader: props?.DocumentReducer?.isActiveLoader || false,
  isRejectedLoader: props?.DocumentReducer?.isRejectedLoader || false,
});

// Action
const mapDispatch = (dispatch) => {
  return {
    userDetail: (id) => dispatch(userDetailAction(id)),
    ChangeUserStatus: (payload, location_redirect, navigate) =>
      dispatch(ChangeUserStatusAction(payload, location_redirect, navigate)),
  };
};

function UserDetail(store) {
  const Isrefresh = useSelector((state) => state?.UserReducer?.refreshState);

  let { NA } = SiteConstants;
  let { TextArea } = Input;

  const { TabPane } = Tabs;
  let selectUsersArray = store.SingleData.SingelUser;
  let [userDetail, setUserDetail] = useState([]);
  let [clickSwitchID, setClickSwitchID] = useState(null);
  let [userMessage, SetUserMessage] = useState("");
  let [isRejectedModalVisible, setRejectedModalVisible] = useState(false);

  let [userId, setUserID] = useState("");

  let [ErrorImage, setErrorImage] = useState({
    user_aadhaar_card_front_img: false,
    user_aadhaar_ard_back_img: false,
    user_pan_card_img: false,
    nominee_aadhar_card_front_img: false,
    nominee_aadhar_card_back_img: false,
    passbook_cancel_cheque_img: false,
    signature_img: false,
    transaction_receipt_img: false,
  });

  let navigate = useNavigate();
  const { id } = useParams();

  async function callUserDetail(id) {
    await store?.userDetail(id);
  }

  useEffect(() => {
    if (Isrefresh) {
      callUserDetail(id);
    }
    //eslint-disable-next-line
  }, [Isrefresh]);

  useEffect(() => {
    callUserDetail(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (selectUsersArray) {
      setUserDetail(selectUsersArray);
    }
    // eslint-disable-next-line
  }, [selectUsersArray]);

  // Rejected
  const RejectedUser = (id) => {
    setUserID(id);
    setRejectedModalVisible(true);
  };

  // Active
  const ActiveUser = (id) => {
    setUserID(id);
    setRejectedModalVisible(false);
    confirm({
      title: "Are you sure you want to active this user?",
      icon: <QuestionCircleOutlined style={{ color: "#030d46" }} />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",

      async onOk() {
        setClickSwitchID(id);
        const payload = {
          status: "ACTIVE",
          user_id: id,
        };
        await store?.ChangeUserStatus(payload, "userdetail", navigate);
      },

      onCancel() {
        setClickSwitchID(null);
      },
    });
  };

  // Click Ok Rejected
  const ClickOkRejected = async () => {
    setUserID(userId);
    setClickSwitchID(userId);

    const payload = {
      user_id: userId,
      status: "REJECTED",
      message: userMessage,
    };
    await store?.ChangeUserStatus(payload, "userdetail", navigate);
    setRejectedModalVisible(false);
    SetUserMessage("");
  };

  // Click Click
  const CancelClick = () => {
    SetUserMessage("");
    setRejectedModalVisible(false);
  };

  let AadharFront = userDetail?.aadharcard_front_image;
  let AadharBack = userDetail?.aadharcard_back_image;
  let Pan_Card = userDetail?.pan_card_front_image;
  let NomineeAadharFront = userDetail?.nominee_aadharcard_front_image;
  let NomineeAadharBack = userDetail?.nominee_aadharcard_back_image;
  let BankDetailsProofImage = userDetail?.bank_details_proof_image;
  let SignatureImage = userDetail?.signature_image;
  let TransactionReceipt = userDetail?.transaction_receipt_image;

  let date_OF_Birth =
    moment(new Date(userDetail?.dob)).format("DD/MM/YYYY") || NA;
  let Nomini_DOB =
    moment(new Date(userDetail?.nominee_dob)).format("DD/MM/YYYY") || NA;

  let applicationDate =
    userDetail?.application_date === undefined
      ? ""
      : moment(new Date(userDetail?.application_date)).format("DD/MM/YYYY") ||
        NA;

  let Exp_Date = moment(userDetail?.valid_till).format("DD/MM/YYYY") || NA;

  const isStoreLoading =
    store?.isActiveLoader === true ||
    store?.isRejectedLoader === true ||
    store?.SelectLoader === true;

  return (
    <SiteLayout>
      {isStoreLoading === true && ""}
      <div className="pgheader">
        <Row>
          <Col xs={24} xl={24}>
            <PageHeader
              onBack={() => navigate(-1) || navigate("/document")}
              title="Go Back"
            ></PageHeader>
          </Col>
        </Row>
      </div>

      <div className="UserDetailScreen">
        <Row>
          <Col xs={24} xl={24}>
            <Tabs type="card">
              <TabPane tab="Membership Proposer Details" key="1">
                <Skeleton
                  active
                  loading={store?.SelectLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Date" bordered>
                    <Descriptions.Item label="Date Of Application" span={2}>
                      <Badge status="processing" text={applicationDate || NA} />
                    </Descriptions.Item>

                    <Descriptions.Item label="Membership Valid Till" span={2}>
                      <Badge status="processing" text={Exp_Date || NA} />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <Skeleton
                  active
                  loading={store?.SelectLoader}
                  paragraph={{ rows: 2 }}
                >
                  <br />
                  <Descriptions title="Facilitator Information" bordered>
                    <Descriptions.Item
                      label="Facilitator"
                      span={3}
                      className="FacilitatorClass"
                    >
                      <Badge
                        status="processing"
                        text={
                          userDetail?.facilitator === "gramhaat"
                            ? "Gram-Haat"
                            : _.capitalize(userDetail?.facilitator) || NA
                        }
                      />
                    </Descriptions.Item>

                    {userDetail?.facilitator === "gramhaat" && (
                      <>
                        <Descriptions.Item label="Facilitator name" span={2}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={NullTxtChkDetailPage(
                              userDetail?.facilitator_name
                            )}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="Facilitator code" span={1}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={
                              userDetail?.facilitator_code?.replace("-", "") ||
                              NA
                            }
                          />
                        </Descriptions.Item>
                      </>
                    )}

                    {userDetail?.facilitator === "agent" && (
                      <>
                        <Descriptions.Item label="Agent name" span={2}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={NullTxtChkDetailPage(userDetail?.agent_name)}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item label="Agent code" span={1}>
                          <Badge
                            className="uppercase"
                            status="processing"
                            text={
                              userDetail?.agent_code?.replace("-", "") || NA
                            }
                          />
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </Skeleton>
                <br />
                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 6 }}
                >
                  <Descriptions title="Proposer Information" bordered>
                    <Descriptions.Item label="Full name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.name)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="MA Yojana number" span={2}>
                      <Badge
                        status="processing"
                        className="uppercase"
                        text={NullTxtChkDetailPage(userDetail?.yojana_number)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of birth" span={2}>
                      <Badge status="processing" text={date_OF_Birth || NA} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Occupation" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.business)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Net monthly income" span={2}>
                      <Badge
                        status="processing"
                        text={
                          (userDetail?.monthly_income === "BELOW 5000" &&
                            "BELOW ₹5000") ||
                          (userDetail?.monthly_income === "5000 TO 10000" &&
                            "₹5000 TO ₹10000") ||
                          (userDetail?.monthly_income === "10000 & ABOVE" &&
                            "₹10000 & ABOVE") ||
                          NA
                        }
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Marital status" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.marital_status)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Total family members" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(
                          userDetail?.total_family_members
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Height (inch)" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.height)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Weight (Kg)" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.weight)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Pre disease (if any)" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.disease)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Primary health" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.primary_health)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Gender" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.gender)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Blood group" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.blood_group)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <br />
                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 6 }}
                >
                  <Descriptions title="Proposer Contact Details" bordered>
                    <Descriptions.Item label="Email" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.email)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Contact Number" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.mobile)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee contact number" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.nomineeNumber)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Whatsapp number" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.whatsapp_no)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Country" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.country)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="State" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.state)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="City / Town / Village" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.city)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="District" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.district)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Taluka" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.taluka)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Pin code" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.pin_code)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Address" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.address)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 3 }}
                >
                  <Descriptions title="Proposer Bank Details" bordered>
                    <Descriptions.Item label="Bank name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.bank_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Branch name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.branch_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Account number" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.account_no)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="IFSC Code" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.ifsc_code)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="UPI ID" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.upi_id)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Proposer Nominee Declaration" bordered>
                    <Descriptions.Item label="Nominee name" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.nominee_name)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee date of birth" span={2}>
                      <Badge status="processing" text={Nomini_DOB || NA} />
                    </Descriptions.Item>

                    <Descriptions.Item label="Nominee relation" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.relative)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
                <br />

                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 3 }}
                >
                  <Descriptions title="Other Information" bordered>
                    <Descriptions.Item label="Self registered vehicle" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(
                          userDetail?.self_registered_vehicle
                        )}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Vehicle type" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.vehicle_type)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="RTO number" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.rto_number)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Insurance (if any)" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={insuranceFunction(userDetail?.insurance)}
                      />
                      {insuranceArray && insuranceArray?.join(", ")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Existing loan" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.existing_loan)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Own property" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={OwnPropertyFunction(userDetail?.own_property)}
                      />
                      {ownPropertyArray && ownPropertyArray?.join(", ")}
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <br />

                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="User Status" bordered>
                    <Descriptions.Item label="Status" span={2}>
                      <Badge status="processing" text={userDetail?.status} />
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Message"
                      span={1}
                      style={
                        ["ACTIVE", "INACTIVE", "REVIEW", "PENDING"].includes(
                          userDetail?.status
                        ) && {
                          display: "table-column",
                        }
                      }
                    >
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.message)}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>
              </TabPane>

              <TabPane tab="Membership Proposer Documents" key="2">
                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 2 }}
                >
                  <Descriptions title="Proposer Statutory Disclosure" bordered>
                    <Descriptions.Item label="Aadhaar card number" span={2}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(
                          userDetail?.aadharcard_number
                        )}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="PAN card number" span={2}>
                      <Badge
                        className="uppercase"
                        status="processing"
                        text={NullTxtChkDetailPage(userDetail?.pan_card_number)}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Nominee aadhaar card number"
                      span={3}
                    >
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(
                          userDetail?.nominee_aadharcard_number
                        )}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Skeleton>

                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 12 }}
                >
                  <div className="site-card-wrapper">
                    <Row gutter={24}>
                      <Col span={2}> </Col>
                      {/* AADHAR FRONT START */}
                      <Col span={4}>
                        <Card
                          title="User Aadhaar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {store.SelectLoader === false &&
                          AadharFront &&
                          AadharFront.split(".").pop().slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={AadharFront}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Front"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!AadharFront ? (
                                <ErrorImageComponent name="Front" />
                              ) : ErrorImage?.user_aadhaar_card_front_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card Front"
                                      src={AadharFront}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          user_aadhaar_card_front_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Front"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Front" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* AADHAR FRONT END */}
                      {/* AADHAR BACK START */}
                      <Col span={4}>
                        <Card
                          title="User Aadhaar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {store.SelectLoader === false &&
                          AadharBack &&
                          AadharBack.split(".").pop().slice(0, 3) === "pdf" ? (
                            <>
                              <a
                                href={AadharBack}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Back"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!AadharBack ? (
                                <ErrorImageComponent name="Back" />
                              ) : ErrorImage?.user_aadhaar_ard_back_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card Back"
                                      src={AadharBack}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          user_aadhaar_ard_back_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Back"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Back" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* AADHAR BACK END */}

                      {/* Pan Card Start */}
                      {/* {Pan_Card?.split("/").pop().slice(0, 4) !== "null" && ( */}
                      {Pan_Card && (
                        <Col span={4}>
                          <Card
                            title="User PAN Card"
                            bordered={false}
                            className="text-center"
                          >
                            {store.SelectLoader === false &&
                            Pan_Card &&
                            Pan_Card.split(".").pop().slice(0, 3) === "pdf" ? (
                              <>
                                <a
                                  href={Pan_Card}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Card hoverable cover={<FilePdfOutlined />}>
                                    <Meta title={"Front"} />
                                  </Card>
                                </a>
                              </>
                            ) : (
                              <>
                                {!Pan_Card ? (
                                  <ErrorImageComponent name="Front" />
                                ) : ErrorImage?.user_pan_card_img === false ? (
                                  <Card
                                    hoverable
                                    cover={
                                      <Image
                                        alt="Pan Card"
                                        src={Pan_Card}
                                        onError={(e) => {
                                          setErrorImage({
                                            ...ErrorImage,
                                            user_pan_card_img: true,
                                          });
                                        }}
                                      />
                                    }
                                  >
                                    <Meta title={"Front"} />
                                  </Card>
                                ) : (
                                  <ErrorImageComponent name="Front" />
                                )}
                              </>
                            )}
                          </Card>
                        </Col>
                      )}

                      {/* Pamd Card End */}
                      {/* Nomini Aadhar card front start */}
                      <Col span={4}>
                        <Card
                          title="Nominee Aadhar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {store.SelectLoader === false &&
                          NomineeAadharFront &&
                          NomineeAadharFront.split(".").pop().slice(0, 3) ===
                            "pdf" ? (
                            <>
                              <a
                                href={NomineeAadharFront}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Front"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!NomineeAadharFront ? (
                                <ErrorImageComponent name="Front" />
                              ) : ErrorImage?.nominee_aadhar_card_front_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card"
                                      src={NomineeAadharFront}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          nominee_aadhar_card_front_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Front"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Front" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>

                      {/* nomini AAdhar back start */}
                      <Col span={4}>
                        <Card
                          title="Nominee Aadhar Card"
                          bordered={false}
                          className="text-center"
                        >
                          {store.SelectLoader === false &&
                          NomineeAadharBack &&
                          NomineeAadharBack.split(".").pop().slice(0, 3) ===
                            "pdf" ? (
                            <>
                              <a
                                href={NomineeAadharBack}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Back"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!NomineeAadharBack ? (
                                <ErrorImageComponent name="Back" />
                              ) : ErrorImage?.nominee_aadhar_card_back_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Aadhar Card"
                                      src={NomineeAadharBack}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          nominee_aadhar_card_back_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Back"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Back" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* nomini AAdhar back end */}
                      <Col span={2}> </Col>
                    </Row>
                  </div>

                  <div className="site-card-wrapper">
                    <Row gutter={24}>
                      <Col span={2}> </Col>
                      {/* Bank Detail Proof START */}
                      <Col span={4}>
                        <Card title="" bordered={false} className="text-center">
                          {store.SelectLoader === false &&
                          BankDetailsProofImage &&
                          BankDetailsProofImage.split(".").pop().slice(0, 3) ===
                            "pdf" ? (
                            <>
                              <a
                                href={BankDetailsProofImage}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Passbook / Cancel Cheque"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!BankDetailsProofImage ? (
                                <ErrorImageComponent name="Passbook / Cancel Cheque" />
                              ) : ErrorImage?.passbook_cancel_cheque_img ===
                                false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Bank Detail Proof"
                                      src={BankDetailsProofImage}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          passbook_cancel_cheque_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Passbook / Cancel Cheque"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Passbook / Cancel Cheque" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>

                      {/* Bank Detail Proof end */}

                      {/* SignatureImage  Start */}
                      <Col span={4}>
                        <Card title="" bordered={false} className="text-center">
                          {store.SelectLoader === false &&
                          SignatureImage &&
                          SignatureImage.split(".").pop().slice(0, 3) ===
                            "pdf" ? (
                            <>
                              <a
                                href={SignatureImage}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Signature"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {!SignatureImage ? (
                                <ErrorImageComponent name="Signature" />
                              ) : ErrorImage?.signature_img === false ? (
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Signature"
                                      src={SignatureImage}
                                      onError={(e) => {
                                        setErrorImage({
                                          ...ErrorImage,
                                          signature_img: true,
                                        });
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Signature"} />
                                </Card>
                              ) : (
                                <ErrorImageComponent name="Signature" />
                              )}
                            </>
                          )}
                        </Card>
                      </Col>
                      {/* SignatureImage end */}
                    </Row>
                  </div>

                  {/* Passbook / Cancel Cheque Start  */}
                </Skeleton>
              </TabPane>

              <TabPane tab="Membership Proposer Payment Details" key="3">
                <Skeleton
                  active
                  loading={store.SelectLoader}
                  paragraph={{ rows: 5 }}
                >
                  <Descriptions title="" bordered>
                    <Descriptions.Item label="Transaction number" span={3}>
                      <Badge
                        status="processing"
                        text={NullTxtChkDetailPage(
                          userDetail?.transaction_number
                        )}
                      />
                    </Descriptions.Item>
                  </Descriptions>

                  {/* Transcation recept image start */}
                  <Row gutter={24}>
                    <Col span={5}>
                      <Card title="" bordered={false} className="text-center">
                        <Card title="" bordered={false} className="text-center">
                          {store.SelectLoader === false &&
                          TransactionReceipt &&
                          TransactionReceipt.split(".").pop().slice(0, 3) ===
                            "pdf" ? (
                            <>
                              {/* if pdf avilable it display */}
                              <a
                                href={TransactionReceipt}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Card hoverable cover={<FilePdfOutlined />}>
                                  <Meta title={"Transaction Receipt"} />
                                </Card>
                              </a>
                            </>
                          ) : (
                            <>
                              {/* else image dispaly */}
                              {!TransactionReceipt ? (
                                <ErrorImageComponent name="Transaction Receipt" /> //if key not exits
                              ) : ErrorImage?.transaction_receipt_img ===
                                false ? (
                                // if error false image dispaly
                                <Card
                                  hoverable
                                  cover={
                                    <Image
                                      alt="Transaction Receipt"
                                      src={TransactionReceipt}
                                      onError={(e) => {
                                        // if error in image set true error in state
                                        setErrorImage({
                                          ...ErrorImage,
                                          transaction_receipt_img: true,
                                        });
                                        // e.currentTarget.src = NoImage;
                                      }}
                                    />
                                  }
                                >
                                  <Meta title={"Transaction Receipt"} />
                                </Card>
                              ) : (
                                // if error true no image display in this component
                                <ErrorImageComponent name="Transaction Receipt" />
                              )}
                            </>
                          )}
                        </Card>
                      </Card>
                    </Col>
                  </Row>

                  {/* recept image end */}
                </Skeleton>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <br />

        {userDetail?.status === "REVIEW" && (
          <Row gutter={24}>
            <Col xs={24} xl={24}>
              <Space size="middle">
                <Button
                  type="primary"
                  onClick={() => ActiveUser(userDetail?._id)}
                  loading={
                    clickSwitchID === userDetail?._id && store?.isActiveLoader
                      ? true
                      : false
                  }
                >
                  ACTIVE
                </Button>

                <Button
                  type="danger"
                  onClick={() => RejectedUser(userDetail?._id)}
                  loading={
                    clickSwitchID === userDetail?._id && store?.isRejectedLoader
                      ? true
                      : false
                  }
                >
                  REJECT
                </Button>
              </Space>

              <Modal
                title="Are you sure you want to reject this user?"
                visible={isRejectedModalVisible}
                onOk={ClickOkRejected}
                onCancel={CancelClick} 
              > Enter reject reason :
                <TextArea 
                  rows={4}
                  placeholder=""
                  value={userMessage}
                  onChange={(e) => SetUserMessage(e.target.value)}
                />
              </Modal>
            </Col>
          </Row>
        )}
      </div>
    </SiteLayout>
  );
}

export default connect(mapState, mapDispatch)(UserDetail);
