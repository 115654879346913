import React from "react";
import { Button, Modal, List } from "antd";

function ExcelResultModal(props) {
  return (
    <>
      <Modal
        className="excelUploadResult"
        title={props?.title}
        visible={props?.visible}
        onCancel={props?.onCancel}
        footer={
          <>
            <Button key="close" type="primary" onClick={props?.onCancel}>
              Close
            </Button>
          </>
        }
      >
        <h3>Total {props?.data?.total}</h3>

        <List
          bordered
          dataSource={[
            `Total duplicate record : ${props?.data?.totalDuplicateRecord}`,
            `Total inserted record : ${props?.data?.totalInserted}`,
            `Total invalid field record : ${props?.data?.totalInvalidField}`,
          ]}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </>
  );
}

export default ExcelResultModal;
