import { Button, Modal } from "antd";
import React, { useState } from "react";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";
import { Descriptions, Row, Col, Card, Badge, Image, Tabs, Empty } from "antd";
import ErrorImageComponent from "../../Reusable/Component/ErrorImageComponent.jsx";

import {
  getColumnData,
  getFacilitator,
  removeDashFromText,
  getDate,
  getIncome,
  insuranceFunction,
  insuranceArray,
  OwnPropertyFunction,
  ownPropertyArray,
} from "../../../Config/CustomFunction";

const { Meta } = Card;
const { TabPane } = Tabs;

const HistoryModel = ({ show, setIsModalOpen, setSingalData, data }) => {
  let [ErrorImage, setErrorImage] = useState({
    user_aadhaar_card_front_img: false,
    user_aadhaar_ard_back_img: false,
    user_pan_card_img: false,
    nominee_aadhar_card_front_img: false,
    nominee_aadhar_card_back_img: false,
    passbook_cancel_cheque_img: false,
    signature_img: false,
    transaction_receipt_img: false,
  });

  return (
    <>
      <Modal
        className="userHistoryModel"
        style={{ top: 10 }}
        width={1380}
        title={data?.policy_number}
        visible={show}
        onCancel={() => {
          setIsModalOpen(false);
          setSingalData(null);
        }}
        footer={
          <>
            <Button
              key="close"
              type="primary"
              onClick={() => {
                setIsModalOpen(false);
                setSingalData(null);
              }}
            >
              Close
            </Button>
          </>
        }
      >
        <Tabs type="card">
          <TabPane tab="Membership Proposer Details" key="1">
            {/* Date Start */}
            <Descriptions title="Date" bordered>
              <Descriptions.Item label="Date Of Application" span={2}>
                <Badge status="processing" text={getDate(data?.start_Date)} />
              </Descriptions.Item>

              <Descriptions.Item label="Membership Valid Till" span={2}>
                <Badge status="processing" text={getDate(data?.end_Date)} />
              </Descriptions.Item>
            </Descriptions>
            <br />
            {/* Facilitator */}
            <Descriptions title="Facilitator Information" bordered>
              <Descriptions.Item
                label="Facilitator"
                span={3}
                className="FacilitatorClass"
              >
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getFacilitator(data?.facilitator)}
                />
              </Descriptions.Item>

              {data?.facilitator === "gramhaat" && (
                <>
                  <Descriptions.Item label="Facilitator name" span={2}>
                    <Badge
                      className="uppercase"
                      status="processing"
                      text={getColumnData(data?.facilitator_name)}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Facilitator code" span={1}>
                    <Badge
                      className="uppercase"
                      status="processing"
                      text={removeDashFromText(data?.facilitator_code)}
                    />
                  </Descriptions.Item>
                </>
              )}

              {data?.facilitator === "agent" && (
                <>
                  <Descriptions.Item label="Agent name" span={2}>
                    <Badge
                      className="uppercase"
                      status="processing"
                      text={getColumnData(data?.agent_name)}
                    />
                  </Descriptions.Item>

                  <Descriptions.Item label="Agent code" span={1}>
                    <Badge
                      className="uppercase"
                      status="processing"
                      text={removeDashFromText(data?.agent_code)}
                    />
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
            <br />
            {/* Proposer Information */}
            <Descriptions title="Proposer Information" bordered>
              <Descriptions.Item label="Full name" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.name)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="MA Yojana number" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.yojana_number)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Date of birth" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getDate(data?.dob)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Occupation" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.business)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Net monthly income" span={2}>
                <Badge
                  status="processing"
                  text={getIncome(data?.monthly_income)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Marital status" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.marital_status)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Total family members" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.total_family_members)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Height (inch)" span={2}>
                <Badge status="processing" text={getColumnData(data?.height)} />
              </Descriptions.Item>
              <Descriptions.Item label="Weight (Kg)" span={2}>
                <Badge status="processing" text={getColumnData(data?.weight)} />
              </Descriptions.Item>

              <Descriptions.Item label="Pre disease (if any)" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.disease)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Primary health" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.primary_health)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Gender" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.gender)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Blood group" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.blood_group)}
                />
              </Descriptions.Item>
            </Descriptions>
            <br />
            {/* Proposer Contact Details */}
            <Descriptions title="Proposer Contact Details" bordered>
              <Descriptions.Item label="Email" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.email)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Contact Number" span={2}>
                <Badge status="processing" text={getColumnData(data?.mobile)} />
              </Descriptions.Item>

              <Descriptions.Item label="Nominee contact number" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.nomineeNumber)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Whatsapp number" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.whatsapp_no)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Country" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.country)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="State" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.state)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="City / Town / Village" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.city)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="District" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.district)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Taluka" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.taluka)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Pin code" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.pin_code)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Address" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.address)}
                />
              </Descriptions.Item>
            </Descriptions>
            <br />
            {/* Proposer Bank Details */}
            <Descriptions title="Proposer Bank Details" bordered>
              <Descriptions.Item label="Bank name" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.bank_name)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Branch name" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.branch_name)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Account number" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.account_no)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="IFSC Code" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.ifsc_code)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="UPI ID" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.upi_id)}
                />
              </Descriptions.Item>
            </Descriptions>
            {/* Proposer Nominee Declaration */}
            <br />
            <Descriptions title="Proposer Nominee Declaration" bordered>
              <Descriptions.Item label="Nominee name" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.nominee_name)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Nominee date of birth" span={2}>
                <Badge status="processing" text={getDate(data?.nominee_dob)} />
              </Descriptions.Item>

              <Descriptions.Item label="Nominee relation" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.relative)}
                />
              </Descriptions.Item>
            </Descriptions>
            <br />
            {/* Other Information */}
            <Descriptions title="Other Information" bordered>
              <Descriptions.Item label="Self registered vehicle" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.self_registered_vehicle)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle type" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.vehicle_type)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="RTO number" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.rto_number)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Insurance (if any)" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={insuranceFunction(data?.insurance)}
                />
                {insuranceArray && insuranceArray?.join(", ")}
              </Descriptions.Item>
              <Descriptions.Item label="Existing loan" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={getColumnData(data?.existing_loan)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Own property" span={2}>
                <Badge
                  className="uppercase"
                  status="processing"
                  text={OwnPropertyFunction(data?.own_property)}
                />
                {ownPropertyArray && ownPropertyArray?.join(", ")}
              </Descriptions.Item>
            </Descriptions>
            {/* User Status */}
            <br />
            <Descriptions
              title="User Status"
              bordered
              className="statusDescription"
            >
              <Descriptions.Item label="Status" span={2}>
                <Badge status="processing" text={data?.status} />
              </Descriptions.Item>
            </Descriptions>
          </TabPane>

          <TabPane tab="Membership Proposer Documents" key="2">
            {/* Proposer Statutory Disclosure */}
            <Descriptions title="Proposer Statutory Disclosure" bordered>
              <Descriptions.Item label="Aadhaar card number" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.aadharcard_number)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="PAN card number" span={2}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.pan_card_number)}
                />
              </Descriptions.Item>

              <Descriptions.Item label="Nominee aadhaar card number" span={3}>
                <Badge
                  status="processing"
                  text={getColumnData(data?.nominee_aadharcard_number)}
                />
              </Descriptions.Item>
            </Descriptions>

            <div className="site-card-wrapper">
              <Row gutter={24}>
                <Col span={2}> </Col>
                {/* AADHAR FRONT START */}
                <Col span={4}>
                  <Card
                    title="User Aadhaar Card"
                    bordered={false}
                    className="text-center"
                  >
                    {data?.aadharcard_front_image &&
                    data?.aadharcard_front_image
                      .split(".")
                      .pop()
                      .slice(0, 3) === "pdf" ? (
                      <>
                        <a
                          href={data?.aadharcard_front_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Front"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.aadharcard_front_image ? (
                          <ErrorImageComponent name="Front" />
                        ) : ErrorImage?.user_aadhaar_card_front_img ===
                          false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Aadhar Card Front"
                                src={data?.aadharcard_front_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    user_aadhaar_card_front_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Front"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Front" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* AADHAR FRONT END */}
                {/* AADHAR BACK START */}
                <Col span={4}>
                  <Card
                    title="User Aadhaar Card"
                    bordered={false}
                    className="text-center"
                  >
                    {data?.aadharcard_back_image &&
                    data?.aadharcard_back_image.split(".").pop().slice(0, 3) ===
                      "pdf" ? (
                      <>
                        <a
                          href={data?.aadharcard_back_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Back"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.aadharcard_back_image ? (
                          <ErrorImageComponent name="Back" />
                        ) : ErrorImage?.user_aadhaar_ard_back_img === false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Aadhar Card Back"
                                src={data?.aadharcard_back_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    user_aadhaar_ard_back_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Back"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Back" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* AADHAR BACK END */}
                {/* Pan Card Start */}
                {data?.pan_card_front_image && (
                  <Col span={4}>
                    <Card
                      title="User PAN Card"
                      bordered={false}
                      className="text-center"
                    >
                      {data?.pan_card_front_image &&
                      data?.pan_card_front_image
                        .split(".")
                        .pop()
                        .slice(0, 3) === "pdf" ? (
                        <>
                          <a
                            href={data?.pan_card_front_image}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Card hoverable cover={<FilePdfOutlined />}>
                              <Meta title={"Front"} />
                            </Card>
                          </a>
                        </>
                      ) : (
                        <>
                          {!data?.pan_card_front_image ? (
                            <ErrorImageComponent name="Front" />
                          ) : ErrorImage?.user_pan_card_img === false ? (
                            <Card
                              hoverable
                              cover={
                                <Image
                                  alt="Pan Card"
                                  src={data?.pan_card_front_image}
                                  onError={(e) => {
                                    setErrorImage({
                                      ...ErrorImage,
                                      user_pan_card_img: true,
                                    });
                                  }}
                                />
                              }
                            >
                              <Meta title={"Front"} />
                            </Card>
                          ) : (
                            <ErrorImageComponent name="Front" />
                          )}
                        </>
                      )}
                    </Card>
                  </Col>
                )}
                {/* Pamd Card End */}
                {/* NOMINI AADHAR FRONT START */}
                <Col span={4}>
                  <Card
                    title="Nominee Aadhar Card"
                    bordered={false}
                    className="text-center"
                  >
                    {data?.nominee_aadharcard_front_image &&
                    data?.nominee_aadharcard_front_image
                      .split(".")
                      .pop()
                      .slice(0, 3) === "pdf" ? (
                      <>
                        <a
                          href={data?.nominee_aadharcard_front_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Front"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.nominee_aadharcard_front_image ? (
                          <ErrorImageComponent name="Front" />
                        ) : ErrorImage?.nominee_aadhar_card_front_img ===
                          false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Aadhar Card"
                                src={data?.nominee_aadharcard_front_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    nominee_aadhar_card_front_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Front"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Front" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* NOMINI AADHAR FRONT END */}
                {/* NOMINI AADHAR BACK  START */}
                <Col span={4}>
                  <Card
                    title="Nominee Aadhar Card"
                    bordered={false}
                    className="text-center"
                  >
                    {data?.nominee_aadharcard_back_image &&
                    data?.nominee_aadharcard_back_image
                      .split(".")
                      .pop()
                      .slice(0, 3) === "pdf" ? (
                      <>
                        <a
                          href={data?.nominee_aadharcard_back_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Back"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.nominee_aadharcard_back_image ? (
                          <ErrorImageComponent name="Back" />
                        ) : ErrorImage?.nominee_aadhar_card_back_img ===
                          false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Aadhar Card"
                                src={data?.nominee_aadharcard_back_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    nominee_aadhar_card_back_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Back"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Back" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* NOMINI AADHAR BACK  END */}
                <Col span={2}> </Col>
              </Row>
            </div>

            <div className="site-card-wrapper">
              <Row gutter={24}>
                <Col span={2}> </Col>
                {/* BANK DETAIL PROOF START */}
                <Col span={4}>
                  <Card title="" bordered={false} className="text-center">
                    {data?.bank_details_proof_image &&
                    data?.bank_details_proof_image
                      .split(".")
                      .pop()
                      .slice(0, 3) === "pdf" ? (
                      <>
                        <a
                          href={data?.bank_details_proof_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Passbook / Cancel Cheque"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.bank_details_proof_image ? (
                          <ErrorImageComponent name="Passbook / Cancel Cheque" />
                        ) : ErrorImage?.passbook_cancel_cheque_img === false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Bank Detail Proof"
                                src={data?.bank_details_proof_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    passbook_cancel_cheque_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Passbook / Cancel Cheque"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Passbook / Cancel Cheque" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* BANK DETAIL PROOF END */}
                {/* SIGNATUREIMAGE START */}
                <Col span={4}>
                  <Card title="" bordered={false} className="text-center">
                    {data?.signature_image &&
                    data?.signature_image.split(".").pop().slice(0, 3) ===
                      "pdf" ? (
                      <>
                        <a
                          href={data?.signature_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Card hoverable cover={<FilePdfOutlined />}>
                            <Meta title={"Signature"} />
                          </Card>
                        </a>
                      </>
                    ) : (
                      <>
                        {!data?.signature_image ? (
                          <ErrorImageComponent name="Signature" />
                        ) : ErrorImage?.signature_img === false ? (
                          <Card
                            hoverable
                            cover={
                              <Image
                                alt="Signature"
                                src={data?.signature_image}
                                onError={(e) => {
                                  setErrorImage({
                                    ...ErrorImage,
                                    signature_img: true,
                                  });
                                }}
                              />
                            }
                          >
                            <Meta title={"Signature"} />
                          </Card>
                        ) : (
                          <ErrorImageComponent name="Signature" />
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* SIGNATUREIMAGE END */}
              </Row>
            </div>
          </TabPane>

          <TabPane tab="Membership Proposer Payment Details" key="3">
            <Descriptions title="" bordered>
              <Descriptions.Item label="Transaction number">
                <Badge
                  status="processing"
                  text={getColumnData(data?.transaction_number)}
                />
              </Descriptions.Item>
            </Descriptions>

            <div className="site-card-wrapper">
              <Row gutter={24}>
                <Col span={5}>
                  <Card title="" bordered={false} className="text-center">
                    <Card title="" bordered={false} className="text-center">
                      {data?.transaction_receipt_image &&
                      data?.transaction_receipt_image
                        .split(".")
                        .pop()
                        .slice(0, 3) === "pdf" ? (
                        <>
                          {/* if pdf avilable it display */}
                          <a
                            href={data?.transaction_receipt_image}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Card hoverable cover={<FilePdfOutlined />}>
                              <Meta title={"Transaction Receipt"} />
                            </Card>
                          </a>
                        </>
                      ) : (
                        <>
                          {/* else image dispaly */}
                          {!data?.transaction_receipt_image ? (
                            <ErrorImageComponent name="Transaction Receipt" /> //if key not exits
                          ) : ErrorImage?.transaction_receipt_img === false ? (
                            // if error false image dispaly
                            <Card
                              hoverable
                              cover={
                                <Image
                                  alt="Transaction Receipt"
                                  src={data?.transaction_receipt_image}
                                  onError={(e) => {
                                    // if error in image set true error in state
                                    setErrorImage({
                                      ...ErrorImage,
                                      transaction_receipt_img: true,
                                    });
                                    // e.currentTarget.src = NoImage;
                                  }}
                                />
                              }
                            >
                              <Meta title={"Transaction Receipt"} />
                            </Card>
                          ) : (
                            // if error true no image display in this component
                            <ErrorImageComponent name="Transaction Receipt" />
                          )}
                        </>
                      )}
                    </Card>
                  </Card>
                </Col>
              </Row>
            </div>
          </TabPane>

          <TabPane tab="Download Documents" key="4">
            <div className="DownloadCertificateScreen">
              <div className="dashboard_wrapper">
                <Row>
                  <Col xs={24} xl={24}>
                    <Descriptions title="" bordered>
                      <Descriptions.Item label="User Name" span={2}>
                        <Badge
                          status="processing"
                          text={getColumnData(data?.name)}
                        />
                      </Descriptions.Item>

                      <Descriptions.Item label="Membership Number" span={2}>
                        <Badge
                          status="processing"
                          text={getColumnData(data?.policy_number)}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>

                <br />

                <Row gutter={10} className="mb-50">
                  <Col
                    xs={{
                      span: 24,
                    }}
                    xl={{
                      offset: 1,
                      span: 4,
                    }}
                    lg={{
                      span: 8,
                    }}
                    md={{
                      span: 12,
                    }}
                    className="dashbord-grid"
                  >
                    {data?.certificate_image ? (
                      <Card title="" bordered={true}>
                        <a
                          href={data?.certificate_image}
                          target="_blank"
                          rel="noreferrer"
                          download={data?.certificate_image}
                        >
                          <Card
                            hoverable
                            cover={
                              <Image
                                preview={false}
                                src={data?.certificate_image}
                                alt="Certificate Image"
                              />
                            }
                          >
                            <Meta title="Certificate Image" />
                          </Card>
                        </a>
                      </Card>
                    ) : (
                      <Card title="" bordered={true}>
                        <Card
                          className="noborder"
                          cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        >
                          <Meta title="Certificate Image" />
                        </Card>
                      </Card>
                    )}
                  </Col>

                  <Col
                    xs={{
                      span: 24,
                    }}
                    xl={{
                      offset: 2,
                      span: 4,
                    }}
                    lg={{
                      span: 8,
                    }}
                    md={{
                      span: 12,
                    }}
                    className="dashbord-grid div2"
                  >
                    {data?.certificate_pdf ? (
                      <Card bordered={true} hoverable>
                        <Card
                          className="dashboardCard"
                          bordered={false}
                          cover={
                            <a
                              href={data?.certificate_pdf}
                              target="_blank"
                              rel="noreferrer"
                              download={data?.certificate_pdf}
                            >
                              <Button
                                className="btn-bg"
                                shape="round"
                                icon={<DownloadOutlined />}
                                size="large"
                              >
                                Download Certificate PDF
                              </Button>
                            </a>
                          }
                        ></Card>
                      </Card>
                    ) : (
                      <Card title="" bordered={true}>
                        <Card
                          className="noborder"
                          cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        >
                          <Meta title="Certificate PDF" />
                        </Card>
                      </Card>
                    )}
                  </Col>

                  <Col
                    xs={{
                      span: 24,
                    }}
                    xl={{
                      offset: 2,
                      span: 4,
                    }}
                    lg={{
                      span: 8,
                    }}
                    md={{
                      span: 12,
                    }}
                    className="dashbord-grid div3"
                  >
                    {data?.invoice_pdf ? (
                      <Card bordered={true} hoverable>
                        <Card
                          className="dashboardCard card3"
                          bordered={false}
                          cover={
                            <a
                              href={data?.invoice_pdf}
                              target="_blank"
                              rel="noreferrer"
                              download={data?.invoice_pdf}
                            >
                              <Button
                                className="btn-bg"
                                shape="round"
                                icon={<DownloadOutlined />}
                                size="large"
                              >
                                Download Invoice PDF
                              </Button>
                            </a>
                          }
                        ></Card>
                      </Card>
                    ) : (
                      <Card title="" bordered={true}>
                        <Card
                          className="noborder"
                          cover={<Empty src={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        >
                          <Meta title="Invoice PDF" />
                        </Card>
                      </Card>
                    )}
                  </Col>

                  <Col
                    xs={{
                      span: 24,
                    }}
                    xl={{
                      offset: 2,
                      span: 4,
                    }}
                    lg={{
                      span: 6,
                    }}
                    md={{
                      span: 12,
                    }}
                    className="dashbord-grid div3"
                  >
                    {data?.application_form_pdf ? (
                      <Card bordered={true} hoverable>
                        <Card
                          className="dashboardCard card4"
                          bordered={false}
                          cover={
                            <a
                              href={data?.application_form_pdf}
                              target="_blank"
                              rel="noreferrer"
                              download={data?.application_form_pdf}
                            >
                              <Button
                                className="btn-bg"
                                shape="round"
                                icon={<DownloadOutlined />}
                                size="large"
                              >
                                Application PDF
                              </Button>
                            </a>
                          }
                        ></Card>
                      </Card>
                    ) : (
                      <Card title="" bordered={true}>
                        <Card
                          className="noborder"
                          cover={
                            <Empty
                              description=""
                              src={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          }
                        >
                          <Meta title="Application PDF Not Available" />
                        </Card>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};
export default HistoryModel;
