import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

console.log(process.env.REACT_APP_CUSTOM_NODE_ENV, "<----");
console.log(process.env.REACT_APP_API_ENDPOINT, "<----");

api.interceptors.request.use(
  function (config) {
    if (config.url !== "admin/login") {
      config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        "Bearer"
      )}`;

      if (
        !window.localStorage.getItem("Bearer") ||
        !window.localStorage.getItem("USER_NAME") ||
        !window.localStorage.getItem("ExpiredToken")
      ) {
        window.location.href = "/";
        window.localStorage.clear();
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default api;
