import React, { useState, useEffect } from "react";
import { LogoutAdmin } from "../../../Action/AdminAuth";
import { CollapsedMenuAction } from "../../../Action/SiteSetting";
import { connect } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Layout, Menu, message, Tag } from "antd";
import moment from "moment";
import "./sitelayout.css";
import { SiteConstants } from "../../../Config/SiteConstants";
import { Helmet } from "react-helmet";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  FileDoneOutlined,
  DashboardOutlined,
  ExceptionOutlined,
} from "@ant-design/icons";

const mapDispatch = (dispatch) => {
  return {
    LogoutDispatch: (navigate) => dispatch(LogoutAdmin(navigate)),
    CollapsedMenuDispatch: (para) => dispatch(CollapsedMenuAction(para)),
  };
};

// State
const mapState = (props) => ({
  SiteSetting: props.SiteSettingReducer,
});

function SiteLayout(store) {
  const location = useLocation();
  const navigate = useNavigate();
  const { Header, Sider, Content, Footer } = Layout;
  const [current, setCurrent] = useState(location.pathname);
  const IsCollapsed = store.SiteSetting.isCollapsedMenu;
  const [collapsed, setCollapsed] = useState(
    IsCollapsed === true ? true : false
  );

  // 1 Min After CHECK
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        window.localStorage.getItem("Bearer") &&
        window.localStorage.getItem("USER_NAME") &&
        window.localStorage.getItem("ExpiredToken")
      ) {
        let getExpiredToken = new Date(
          window.localStorage.getItem("ExpiredToken")
        );
        let getCurrentDateTime = new Date(
          moment().format("YYYY-MM-DD HH:mm:ss")
        );

        if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "production") {
          console.log("getCurrentDateTime: ", getCurrentDateTime);
          console.log("getExpiredTokenDateTime: ", getExpiredToken);
        }

        if (getCurrentDateTime >= getExpiredToken) {
          message.error("Your session is expired, please login again");
          store.LogoutDispatch(navigate);
        }
      }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    store.CollapsedMenuDispatch(collapsed);
    // eslint-disable-next-line
  }, [collapsed]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  function handleClick(e) {
    setCurrent(e.key);
  }

  function Logout() {
    store.LogoutDispatch(navigate);
  }

  const CustomTitle = () => {
    if (current === "/dashboard") {
      return `Dashboard - ${SiteConstants.gigSureLabel}`;
    }
    if (current === "/document") {
      return `Review Document - ${SiteConstants.gigSureLabel}`;
    }
    if (current === "/users") {
      return `Users - ${SiteConstants.gigSureLabel}`;
    }
    if (current === "/setting") {
      return `Setting - ${SiteConstants.gigSureLabel}`;
    }
    if (current.includes("/userdetail")) {
      return `User detail - ${SiteConstants.gigSureLabel}`;
    }
    if (current.includes("/edit-users")) {
      return `Edit Membership proposal form - ${SiteConstants.gigSureLabel}`;
    }

    if (current.includes("/download-user-data")) {
      return `Download user data - ${SiteConstants.gigSureLabel}`;
    }

    if (current.includes("/importusers")) {
      return `Import user details - ${SiteConstants.gigSureLabel}`;
    }

    if (current.includes("/failedusers")) {
      return `Failed imported users - ${SiteConstants.gigSureLabel}`;
    }

    if (current.includes("/user-history")) {
      return `User history - ${SiteConstants.gigSureLabel}`;
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>{CustomTitle()}</title>
      </Helmet>

      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Link to="/dashboard">
          <div className="logo" />
        </Link>

        <Menu
          theme="dark"
          onClick={handleClick}
          selectedKeys={[current]}
          mode="inline"
          defaultSelectedKeys={["dashboard"]}
        >
          <Menu.Item
            key="dashboard"
            className={current === "/dashboard" && "activeClass"}
            icon={<DashboardOutlined />}
          >
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item
            key="document"
            className={current === "/document" && "activeClass"}
            icon={<FileDoneOutlined />}
          >
            <Link to="/document">Review document</Link>
          </Menu.Item>

          <Menu.Item
            key="users"
            className={current === "/users" && "activeClass"}
            icon={<UsergroupAddOutlined />}
          >
            <Link to="/users">Users</Link>
          </Menu.Item>

          <Menu.Item
            key="importusers"
            className={current === "/importusers" && "activeClass"}
            icon={<ExceptionOutlined />}
          >
            <Link to="/importusers">Import user details</Link>
          </Menu.Item>

          <Menu.Item
            key="setting"
            className={current === "/setting" && "activeClass"}
            icon={<SettingOutlined />}
          >
            <Link to="/setting">Setting</Link>
          </Menu.Item>

          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={() => Logout()}
          >
            <Link to="/">Logout</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div className="topnav-right">
            <Tag color="default">
              Welcome : {window.localStorage.getItem("USER_NAME")}
            </Tag>
          </div>
        </Header>

        <Content
          className="site-layout-background"
          style={{ padding: 24, minHeight: 800 }}
          //  margin: "24px 16px",
        >
          <div className="mainDiv">{store.children} </div>
        </Content>

        <Footer
          style={{
            textAlign: "center",
          }}
        >
          © {new Date().getFullYear()} {SiteConstants.gigSureLabel} · All Right
          Reserved
        </Footer>
      </Layout>
    </Layout>
  );
}

export default connect(mapState, mapDispatch)(SiteLayout);
